import React from "react";
// import { Link } from "gatsby"

import Layout from "../components/layout";

import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO
      title="New Hope Pet Center"
      keywords={[
        `New Hope Pet Center`,
        `New Hope`,
        `Pet Center`,
        `Pet Grooming`,
        `Pet Supplies`
      ]}
    />
    <div className="mw7 center">
      <div className="tc mv5 ph3">
        <h1 className="f3 f2-ns lh-title">New Hope Pet Center</h1>
        <h2 className="lh-title">
          Professional Grooming &amp; Premium Quality Products (since 1981)
        </h2>
        <p className="f5 lh-title">
          Call{" "}
          <a href="tel:2158622778" className="link no-underline bb green fw8">
            (215) 862-2778
          </a>{" "}
          to make an appointment or ask any questions
        </p>
      </div>

      <div className="cf dt-l w-100 pv4-ns">
        <div className="dtc-l v-mid db mw6 center pr3-l tc">
          <img
            className="mw-100"
            src={require("../images/nhpc-illustration.jpg")}
            alt="New Hope Pet Center"
          />
        </div>
        <div className="dtc-l v-mid f6 f5-m f4-l measure-l lh-copy mw6 center ph3">
          <p className="fw8">
            We are located in Logan Square outside of beautiful New Hope, PA.
          </p>
          <p className="f5">
            You get a level of care and expertise that is unmatched, and our
            great customers will even tell you themselves.
          </p>
          <p className="f5">
            In addition to our professional grooming services, we carry a full
            line of your favorite pet products for dogs, cats, reptile, fish,
            and small animals. Our selection of premium dog and cat foods
            includes grain free, holistic, and L.I.D. lines.
          </p>
        </div>
      </div>

      <div className="lh-copy w-100 tl f5 f4-ns mh0 mv4 ph6 pv3 bg-light-green b ba b--green br3">
        <p class="f3 tc fw8 bb b--green pb3">
          An important announcement for the months of July and August 2024:
        </p>

        <p class="f5">
          We will be CLOSED on SATURDAY and SUNDAY FOR THE MONTHS OF JULY AND AUGUST
        </p>

        <p class="f5">
          NO CHANGES IN OUR GROOMING SCHEDULE.
        </p>

        <p class="f5">
          Appointments available from 8:00a.m. – 10:00a.m.
        </p>

        <p class="f5">
          Regular retail and grooming hours will remain.
          <br />
          Monday thru Friday – 9:00a.m. to 6:00 p.m.
        </p>

        <p class="f5">
          Be sure to stop by for your supplies and food.
          <br />Thank you,
          <br />-Sue and Ron
        </p>
      </div>

      <div className="lh-copy w-100 tc f5 f4-ns i mh0 mv4 pa3 bg-washed-blue">
        “We have definitely found our babies their 'forever' groomer!”
        <br />– Diane Clark
      </div>

      <div className="tc pb4">
        <b className="db pa3">Retail Store Hours</b>
        <div className="pa2">Weekdays 9:00 – 6:00</div>
        <div className="pa2">Saturday 10:00 – 5:00</div>
        <div className="pa2">Sunday 11:00 – 4:00</div>
        <b className="db pa3">Grooming Hours: by Appointment</b>
        <div className="pa2">Monday – Friday</div>
        <div className="pa2">Beginning at 8:00a.m.</div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
